<template>
	<v-navigation-drawer
		id="default-drawer"
		class="secondary-contrast-background"
		mini-variant-width="80"
		app
		width="200"
		fixed
		><template>
			<nav>
				<ul>
					<v-list-item
						v-for="(item, index) in menuItems"
						class="secondary-contrast-background contrast-text"
						:key="index"
						:to="item.href"
						light
						exact
					>
						<span class="icon" :class="'ico-' + item.label"></span>
						{{ item.title }}
					</v-list-item>
				</ul>
			</nav>
		</template>
	</v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	name: "AdminDefaultDrawer",
	computed: {
		...mapGetters(["menuItems"])
	}
};
</script>

<style lang="scss">
#default-drawer {
	top: 100px !important;
	width: 250px !important;
	background-color: #f2f2f2 !important;

	nav {
		padding-top: 30px;
	}

	.secondary-contrast-background {
		background-color: #f2f2f2 !important;
		color: #d1d1d1 !important;
		font-size: 15px;

		.icon {
			display: inline-block;
			width: 21px;
			height: 21px;
			margin-right: 10px;
			&.ico-dashboard {
				background-image: url("~@/assets/Home.svg");
			}
			&.ico-courses {
				background-image: url("~@/assets/Courses.svg");
			}
			&.ico-quizes {
				background-image: url("~@/assets/Quizes.svg");
			}
			&.ico-certificates {
				background-image: url("~@/assets/Certificate.svg");
			}
			&.ico-organization {
				background-image: url("~@/assets/Organization.svg");
			}
		}

		&.v-list-item--active {
			color: #000 !important;

			.icon {
				&.ico-dashboard {
					background-image: url("~@/assets/Home-selected.svg");
				}
				&.ico-courses {
					background-image: url("~@/assets/Courses-selected.svg");
				}
				&.ico-quizes {
					background-image: url("~@/assets/Quizes-selected.svg");
				}
			}
		}
	}

	.v-navigation-drawer__border {
		background-color: #fff;
	}
}
</style>
